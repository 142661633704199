import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        min-height: 100vh;
        position: relative;
        background-color: ${variables.dpc_backgroundWhite};

        .content {
            .body {
                margin-top: 5em;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                .headline {
                    font-size: 4em;
                    color: ${variables.dpc_fontDark};
                    font-weight: 600;
                    line-height: 90%;
                }
                .subheadline {
                    font-size: 1.2em;
                    color: ${variables.dpc_fontDark};
                    font-weight: 300;
                    margin-top: 2em;
                }
                .component-button {
                    margin: 2em auto;
                }
            }
        }

        @media (max-width: ${variables.tabletL}) {
            .body {
                .headline {
                    font-size: 2.8em;
                }
            }
        }
        @media (max-width: ${variables.mobileL}) {
            .body {
                .headline {
                    font-size: 2.4em;
                }
            }
        }
    `;

