import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Button from 'Components/layout/Button';

export default class PublicReservationsOnlineConfirm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        return (
            <StyledComponent
                className={classnames({
                    'component-reservations-online-confirm': true,
                })}
                styles={require('./styles')}
            >
                <Head
                    title="Rezerwacje treningów"
                    description="Rezerwacje treningów FitAdept"
                />
                <div className="content content-gym-select">
                    <LayoutContainer>
                        <div className="body">
                            <h1 className="headline">Dziękujemy</h1>
                            <p className="subheadline">
                                Gotowe! Proste, prawda? Na maila otrzymasz potwierdzenie swojego treningu!
                                <br />
                                Do zobaczenia na treningu niebawem :)
                            </p>
                            <a href="https://fitadept.com">
                                <Button
                                    styleVersion={2}
                                    size="large"
                                >
                                    Poznaj FitAdept
                                </Button>
                            </a>
                        </div>
                    </LayoutContainer>
                </div>
            </StyledComponent>
        );
    }
}
